(function($, options) {
    $(function() {
      options.windowWidth = window.innerWidth;

      let urlParam = location.search.substring(1);
      if(urlParam) {
        $('#corporate').scrollingChangeURL({margin: 300});
      }

        const $sec = $('.sec__corporate');
        let $sec_btn;
        let $btn_close;
        let mode = 'pc';
        let openFlag;
        const setSpEvent = function(){
            if(options.windowWidth > options.breakpoints.tab) {
                if(mode === 'tab') {
                    $sec_btn.off('touchend').remove();
                    $btn_close.off('touchend').remove();
                    $sec.removeClass('open');
                    $sec.find('.sec__body').attr('style', '');
                    mode = 'pc';
                }
            } else {
                if(mode === 'pc') {
                    if ($sec.hasClass('en')) {
                      $sec.find('>header').append('<p class="m-btn__open"><span>More</span></p>');
                      $sec.find('.sec__body').append('<p class="m-btn__close"><span>Close</span></p>');
                    }else{
                      $sec.find('>header').append('<p class="m-btn__open"><span>モットみる</span></p>');
                      $sec.find('.sec__body').append('<p class="m-btn__close"><span>とじる</span></p>');
                    }
                    $sec_btn = $('.m-btn__open span');
                    $btn_close = $('.m-btn__close span');
                    openFlag = (()=>{
                        var arr = [];
                        for(var i = 0; i < $sec_btn.length; i++) {
                            arr[i] = false;
                        }
                        return arr;
                    })();
                    const secOpen = function(index) {
                        const $self = $sec.eq(index);
                        const $body = $self.find('.sec__body');
                        const open = ($self.attr('class').match(/open/))? true : false;
                        if(!openFlag[index]) {
                            openFlag[index] = true;
                            if(open) {
                                if ($sec.hasClass('en')) {
                                  $sec_btn.eq(index).text('More');
                                }else{
                                  $sec_btn.eq(index).text('モットみる');
                                }
                                $body.velocity("slideUp",
                                    {
                                        duration: 500,
                                        complete: function() {
                                            openFlag[index] = false;
                                        }
                                    }
                                );
                            } else {
                                if ($sec.hasClass('en')) {
                                  $sec_btn.eq(index).text('Close');
                                }else{
                                  $sec_btn.eq(index).text('とじる');
                                }
                                $body.velocity("slideDown",
                                    {
                                        duration: 500,
                                        complete: function() {
                                            openFlag[index] = false;
                                        }
                                    }
                                );
                            }
                            $self.toggleClass('open');
                        }
                    }
                    $sec_btn.on('touchend click', function(){
                        const index = $sec_btn.index(this);
                        // console.log($sec.eq(index).find('.sec__body'))
                        // $sec.eq(index).find('.sec__body').velocity("scroll", { duration: 500, offset: -62, easing: "swing" });
                        secOpen(index);
                    });
                    $btn_close.on('touchend click', function(){
                        const index = $btn_close.index(this);
                        secOpen(index);
                        $sec.eq(index).velocity("scroll", { duration: 500, offset: -62, easing: "swing" });
                    });
                    mode = 'tab';
                }
            }
        }

        $(window).on('resize', function(){
            setSpEvent();
        });

        const init = (()=>{
            setSpEvent();
        })();


        $.getJSON('/corporate/corporate-map.json', {}, function(json){

          $.each(json, function(i, obj){
            let htmlStr = '';
            i += 1;
            let region = obj.region,
                base = obj.base,
                htmlStrwrap = '';
            $.each(base, function(j, value){
              let side = value.side,
                  length = value.length,
                  left = value.left,
                  top = value.top,
                  url = value.url,
                  linkNone = [],
                  extension = value.extension,
                  name = value.name;
                if ( url == '') {
                  linkNone = 'class="link__none"';
                }
                htmlStr += '<li style="background-position: ' + side + ' ' + length + ';background-size: ' + extension + '"><div class="corporate-map__div"><a href="' + url + '" '+ '' + linkNone +' target="_blank">'+ name + '</a><div></li>';
            });
            htmlStrwrap = '<nav><div class="corporate-map__wrapper corporate-map__wrapper' + i + '"><ul class="corporate-map__company">'+ htmlStr + '</ul></div></nav>';
            $('#map').append(htmlStrwrap);
          });

          for (var i = 1; i < 4; i++) {
            let map = {};
            map.wrap = $('.corporate-map__wrapper' + i);
            map.ul = map.wrap.find('ul');
            map.li = map.ul.find('li');
            map.len = map.li.length;
            map.wrapwidth = map.wrap.width();
            map.wrapHalf = map.wrapwidth / 2;
            map.width = (map.li.width() + 16) * map.len - 16;
            map.distance = (Math.round(map.width - map.wrapwidth) > 0)? Math.round(map.width - map.wrapwidth)/2 : 0;
            map.speed = 0;
            map.position = -map.distance;
            map.interval;
            if(options.windowWidth >= options.breakpoints.pc) {
              map.ul.css({transform: `translate(${map.position}px)`});
            }
            map.move = function() {
                map.position = map.position + (map.speed / 10);
                if(map.speed > 0 && map.position > 0) {
                    map.position = 0;
                } else if(map.speed < 0 && map.position < -map.distance*2) {
                    map.position = -map.distance*2;
                }
                map.ul.css({transform: `translate(${map.position}px)`});
                map.interval = setTimeout(map.move, 33);
            }

            map.wrap.on({
                'mouseenter': function() {
                    if(options.windowWidth >= options.breakpoints.pc) {
                        map.move();
                    }
                    $(this).on('mousemove', function(e){
                        const x = e.clientX;
                        if(x < map.wrapHalf) {
                            map.speed = Math.round( (map.wrapHalf - x) / map.wrapHalf * 100 );
                        } else {
                            map.speed = Math.round( -(x - map.wrapHalf) / map.wrapHalf * 100 );
                        }
                    });
                },
                'mouseleave': function() {
                    map.wrap.off('mousemove');
                    map.speed = 0;
                    clearTimeout(map.interval);
                }
            });

            $(window).on('resize', function(){
                // options.windowWidth = $(window).width();
                options.windowWidth = window.innerWidth;
                map.wrapwidth = map.wrap.width();
                map.wrapHalf = map.wrapwidth / 2;
                map.width = map.ul.width();

                if(options.windowWidth <= options.breakpoints.pc) {
                  map.ul.removeAttr('style');
                }

            });

          }

          if(options.windowWidth >= options.breakpoints.pc) {
            const _this = $('#map .corporate-map__company>li>div');
            _this.hover(
              function(e) {
                const _this = $(this).parent();
                const _class = _this.attr('class');
                let _position = _this.css(['background-position']);
                let _sizeValue = parseInt(_this.css('background-size'));
                _this.parents('.corporate-map__group').prev().prev().removeClass();
                _this.parents('.corporate-map__group').prev().prev().addClass('corporate-map__body').css(_position).css('background-size','250%');
                _this.parents('.corporate-map__group').prev().prev().children().removeClass();
                _this.parents('.corporate-map__group').prev().prev().children().css('background-size','250%').css('opacity','1');
              },
              function(e) {
                _this.parents('.corporate-map__group').prev().prev().children().css('opacity','0');
              }
            );
          }

        });

        // let agent = window.navigator.userAgent.toLowerCase();
        // if(agent.indexOf('iphone') != -1 || agent.indexOf('ipad') != -1 || agent.indexOf('android') != -1) {
        // }else if(agent.indexOf("edge") > -1 || agent.indexOf("chrome") > -1){
        //   $('#philosophy>header').prepend('<video id="intro" src="/mov/intro.mp4" autoplay muted></video><video id="loop" src="/mov/loop.mp4" autoplay loop></video>');
        // }else if (agent.indexOf("safari") > -1){
        //   $('#philosophy>header').prepend('<video id="intro" src="/mov/intro.mp4" autoplay muted></video><video id="loop" src="/mov/loop.mp4" autoplay loop></video>');
        // }else{
        //   $('#philosophy>header').prepend('<video id="intro" src="/mov/intro.mp4" autoplay muted></video><video id="loop" src="/mov/loop.mp4" autoplay loop></video>');
        // }

        let o = $.extend({
          url:  $(location).attr('href'),
          header: $('#globalHeaderAll').innerHeight(),
          anchor: '',
          target: '',
          pos: '',
          speed: '400'
        }, options);

        $(window).on('load', function() {
          $.fn.mapChild_paging = function() {
              const _this = $(this);
              const group = _this.find('.corporate-map__group');
              const btn = _this.find('.corporate-map__region button');
              let page = Number(_this.attr('data-page'));
              btn.on('click', function() {
                  const index = btn.index(this) + 1;
                  const _btn = $(this);
                  const _class = _btn.attr('class');
                  const current = (_class)? (_class.match(/current/))? true : false : false;
                  if(!current) {
                      btn.removeClass('current');
                      _btn.addClass('current');
                      group.attr('data-page', index);
                  }
                  if(options.windowWidth >= options.breakpoints.pc) {
                    btn.parent().prev().removeClass().removeAttr('style');
                    btn.parent().prev().children().removeClass().removeAttr('style');
                    btn.parent().prev().addClass('corporate-map__body').addClass(_class);
                  }
                  if(options.windowWidth <= options.breakpoints.pc) {
                    let groupHeight = group.children().eq(index-1).find('.corporate-map__company').height();
                    group.css('height', groupHeight + 'px');
                    group.children().css('height', groupHeight + 'px');
                  }

              });
              $(window).on('resize', function(){
                if(options.windowWidth >= options.breakpoints.pc) {
                  group.removeAttr('style');
                  group.children().removeAttr('style');
                }
              });
          }
          $('#directory .sec__body').mapChild_paging();

          if(o.url.indexOf("#") != -1){
              o.anchor = o.url.split("#");
              o.target = $('#' + o.anchor[o.anchor.length - 1]);
            if(o.target.length){
              o.pos = Math.floor(o.target.offset().top) - o.header;
              $("html, body").animate({scrollTop: o.pos}, 0);
            }
          }

          setTimeout(function(){
            $('#loop').fadeIn(300);
            $('#philosophyText').fadeIn(800);
            $('#intro').fadeOut(800);
          },3500);

        });

    });
})(jQuery, options);


const corporateLink = () => {
  window.onload = function(){
    const dir = location.href.split("/");
    const pass = dir[dir.length -2];
    const hash = '#'+ pass;
    const target = $(hash);
        if (target.offset()) {
          const position = target.offset().top;
          const headerHeight = document.getElementById('globalHeaderAll').clientHeight;
          const scrollPosition = position - headerHeight;
          $('body,html').stop().animate({scrollTop: scrollPosition},0);
        }
  }
};
corporateLink();
